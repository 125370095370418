import { ColorModeScript } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { getEnvType, getEnvVar } from "./shared/util";

const sentryDSN = getEnvVar("REACT_APP_SENTRY_DSN");

Sentry.init({
  dsn: sentryDSN,
  enabled: sentryDSN != null,
  release: getEnvVar("REACT_APP_GIT_SHA"),
  environment: getEnvType(),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Srror sample rate
  sampleRate: 1,
  // Transaction sample rate
  tracesSampleRate: 0.1,
  // Replay sampling: https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#sampling
  // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
  replaysSessionSampleRate: 0,
  // The sample rate for replays that are recorded when an error happens. This type of replay will record up
  // to a minute of events prior to the error and continue recording until the session ends.
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
